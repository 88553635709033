import Cookie from 'universal-cookie';
import { DOMAIN_NAME } from '../../constants';

/**
 * Gets all available cookies per environment.
 * Cookies source can be explicit passed via cookies object or by default with document cookie object.
 * @param {Object} [headerCookies] The current request header cookies (relevant for SSR only). Defaults to "document.cookies" in the client, and an empty string in the server.
 * @return {Object}
 */
const getAll = (headerCookies?: any) => {
    const cookieSource = typeof document === 'undefined' ? headerCookies : document.cookie;

    if (!cookieSource) {
        return {};
    }

    return new Cookie(cookieSource).getAll();
};

/**
 * Sets a given cookie value over fiverr domain (relevant for CSR only) .
 * @param {String} name The cookie's name.
 * @param {any} value The cookie's value.
 * @param {Number} expires The time the cookie should expire, in milliseconds from now.
 */
const set = (name: string, value: any, expires: number) => {
    const options = {
        expires: new Date(Date.now() + expires),
        domain: DOMAIN_NAME,
    };

    new Cookie().set(name, value, options);
};

/**
 * Removes a given cookie name from document's cookies
 * @param {String} name The name of the cookie to be removed.
 */
const remove = (name: string) => new Cookie().remove(name);

export default {
    getAll,
    set,
    remove,
};
