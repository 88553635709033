import cookie from '../../lib/cookie';
import { sendBigQuery, statsCount, trackMixpanel, METRICS, EVENTS, MIXPANEL_EVENTS } from '../tracking';
import {
    ONETRUST_SELECTORS,
    CLOSED_BANNER_COOKIE_NAME,
    COOKIES_EXPIRES,
    CATEGORIES_MAPPING,
    ONE_TRUST_LOADED_EVENT,
} from './constants';

/**
 * The OneTrust analytics locale script state.
 * @type {{category: undefined}}
 *
 * @TODO
 * Find out why category is defined but not used
 * Is it a typo ? `detailsCategory` is in use
 */
const state: any = {
    category: undefined,
};

/**
 * Checks if current navigator user agent is determined as Safari included
 * @return {Boolean}
 */
const isSafari = (): boolean => navigator.userAgent.indexOf('Safari') > -1;

/**
 * Returns the closed banned cookie if present
 * @return {String|undefined}
 */
const getClosedBannerCookie = (): string | undefined => {
    const cookies = cookie.getAll();
    return cookies[CLOSED_BANNER_COOKIE_NAME];
};

/**
 * Updates current closed banner cookie value if has been seen within expiry date.
 *
 * @TODO
 * Find out why its needed - make no sesnse.
 * cookie expiration could handle cookie clean up by its own.
 */
const updateClosedBannerCookie = (closedCookie) => {
    if (!closedCookie) {
        return;
    }

    const closedAgo = new Date(closedCookie).getTime();
    if (Date.now() - closedAgo < COOKIES_EXPIRES) {
        cookie.set(CLOSED_BANNER_COOKIE_NAME, closedCookie, COOKIES_EXPIRES);
    }
};

/**
 * Safely adds an DOM event listener on a given element
 * @param {HTMLElement} element - The element to attach the listener to.
 * @param {String} name - The event name.
 * @param {Function} callback - The callback to trigger upon event.
 */
const safeAddListener = (element: HTMLElement | null | Element, name: string, callback: (event: any) => void) => {
    if (!element) {
        return;
    }
    element.addEventListener(name, callback);
};
/**
 * Returns a given HTMLElement "Category" defined data attribute
 * @param {HTMLElement} element - The element to extract category from.
 * @param {String} attribute - The attribute that marks the holder category value.
 * @return {String|undefined}
 */
const getElementCategory = (element: HTMLElement | Element, attribute = 'data-optanongroupid'): string | undefined =>
    CATEGORIES_MAPPING[element.getAttribute(attribute) as keyof typeof CATEGORIES_MAPPING];

/**
 * The cookie banner details container events.
 */
const detailsEvents = () => {
    const allowAllSettings = document.getElementById(ONETRUST_SELECTORS.ALLOW_ALL_SETTINGS);
    const settingsSearch = document.getElementById(ONETRUST_SELECTORS.SETTINGS_SEARCH);
    const allowSetting = Array.from(document.querySelectorAll(`.${ONETRUST_SELECTORS.ALLOW_SETTINGS}`));
    const settingDetails = Array.from(document.querySelectorAll(`.${ONETRUST_SELECTORS.SETTINGS_DETAILS}`));

    safeAddListener(allowAllSettings, 'click', () => {
        sendBigQuery(EVENTS.ALLOW_ALL_SETTINGS, {
            cookie_category: state.detailsCategory,
        });
        trackMixpanel(MIXPANEL_EVENTS.ALLOW_ALL_SETTINGS, {
            'Cookie Category': state.detailsCategory,
        });
    });

    safeAddListener(settingsSearch, 'input', ({ target }) => {
        const { value } = target;

        sendBigQuery(EVENTS.SETTINGS_SEARCH, {
            cookie_category: state.detailsCategory,
            query: value,
        });
        trackMixpanel(MIXPANEL_EVENTS.SETTINGS_SEARCH, {
            'Cookie Category': state.detailsCategory,
            Query: value,
        });
    });

    allowSetting.forEach((allowSettingHolder) => {
        const host = allowSettingHolder.getAttribute('aria-label');

        safeAddListener(allowSettingHolder, 'click', (event) => {
            event.stopPropagation();
            sendBigQuery(EVENTS.ALLOW_SETTINGS, {
                cookie_category: state.detailsCategory,
                host,
            });
            trackMixpanel(MIXPANEL_EVENTS.ALLOW_SETTINGS, {
                'Cookie Category': state.detailsCategory,
                Host: host,
            });
        });
    });

    settingDetails.forEach((settingDetailsHolder) => {
        safeAddListener(settingDetailsHolder, 'click', () => {
            sendBigQuery(EVENTS.VIEW_DETAILS, {
                cookie_category: state.detailsCategory,
            });
            trackMixpanel(MIXPANEL_EVENTS.VIEW_DETAILS, {
                'Cookie Category': state.detailsCategory,
            });
        });
    });
};

/**
 * The cookie banner settings container events.
 */
const settingsEvents = () => {
    const close = document.getElementById(ONETRUST_SELECTORS.SETTINGS_CLOSE);
    const categories = Array.from(
        document.querySelectorAll(`.${ONETRUST_SELECTORS.CATEGORIES_GROUP} .${ONETRUST_SELECTORS.CATEGORIES_ITEMS}`)
    );
    const toggles = Array.from(document.querySelectorAll(`.${ONETRUST_SELECTORS.CATEGORIES_TOGGLES}`));
    const details = Array.from(document.querySelectorAll(`.${ONETRUST_SELECTORS.CATEGORIES_DETAILS}`));
    const moreInfo = document.querySelector(`.${ONETRUST_SELECTORS.SETTINGS_MORE_INFO}`);
    const confirm = document.querySelector(`.${ONETRUST_SELECTORS.CONFIRM_SETTINGS}`);

    safeAddListener(close, 'click', () => {
        sendBigQuery(EVENTS.SETTINGS_CLOSE);
        trackMixpanel(MIXPANEL_EVENTS.SETTINGS_CLOSE);
    });

    safeAddListener(moreInfo, 'click', (event) => {
        event.stopPropagation();
        sendBigQuery(EVENTS.MORE_INFO);
        trackMixpanel(MIXPANEL_EVENTS.MORE_INFO);
    });

    safeAddListener(confirm, 'click', (event) => {
        event.stopPropagation();
        sendBigQuery(EVENTS.CONFIRM);
        trackMixpanel(MIXPANEL_EVENTS.CONFIRM);
    });

    categories.forEach((categoryHolder) => {
        safeAddListener(categoryHolder, 'click', ({ target }) => {
            if (target.tagName.toLowerCase() === 'label') {
                return;
            }

            sendBigQuery(EVENTS.SELECT_CATEGORY, {
                cookie_category: getElementCategory(categoryHolder),
            });

            trackMixpanel(MIXPANEL_EVENTS.SELECT_CATEGORY, {
                'Cookie Category': getElementCategory(categoryHolder),
            });
        });
    });

    toggles.forEach((toggleHolder) => {
        safeAddListener(toggleHolder, 'click', (event) => {
            event.stopPropagation();

            sendBigQuery(EVENTS.ALLOW_CATEGORY, {
                cookie_category: getElementCategory(toggleHolder),
            });

            trackMixpanel(MIXPANEL_EVENTS.ALLOW_CATEGORY, {
                'Cookie Category': getElementCategory(toggleHolder),
            });
        });
    });

    details.forEach((detailsHolder) => {
        const category = getElementCategory(detailsHolder, 'data-parent-id');

        safeAddListener(detailsHolder, 'click', (event) => {
            event.stopPropagation();
            state.detailsCategory = category;

            sendBigQuery(EVENTS.VIEW_SETTINGS_DETAILS, {
                cookie_category: category,
            });

            trackMixpanel(MIXPANEL_EVENTS.VIEW_SETTINGS_DETAILS, {
                'Cookie Category': category,
            });
        });
    });
};

/**
 * The main cookie banner popup events.
 */
const popupEvents = () => {
    const acceptButton = document.getElementById(ONETRUST_SELECTORS.ACCEPT);
    const rejectAll = document.getElementById(ONETRUST_SELECTORS.REJECT_ALL);
    const allowAllButton = document.getElementById(ONETRUST_SELECTORS.ALLOW_ALL_BUTTON);
    const closeButton = document.getElementById(ONETRUST_SELECTORS.CLOSE_BANNER);
    const settingsButton = document.getElementById(ONETRUST_SELECTORS.SETTINGS_BUTTON);

    if (!getClosedBannerCookie()) {
        safeAddListener(acceptButton, 'click', () => {
            statsCount(METRICS.ACCEPT);
            sendBigQuery(EVENTS.ACCEPT);
            trackMixpanel(MIXPANEL_EVENTS.ACCEPT);
        });
    }

    safeAddListener(rejectAll, 'click', () => {
        sendBigQuery(EVENTS.REJECT_ALL);
        trackMixpanel(MIXPANEL_EVENTS.REJECT_ALL);
    });

    safeAddListener(allowAllButton, 'click', () => {
        sendBigQuery(EVENTS.ALLOW_ALL_PREFERENCES);
        trackMixpanel(MIXPANEL_EVENTS.ALLOW_ALL_PREFERENCES);
    });

    safeAddListener(closeButton, 'click', () => {
        sendBigQuery(EVENTS.CLOSE);
        trackMixpanel(MIXPANEL_EVENTS.CLOSE);
    });

    safeAddListener(settingsButton, 'click', () => {
        statsCount(METRICS.SETTINGS_CLICK);
        sendBigQuery(EVENTS.SETTINGS_CLICK);
        trackMixpanel(MIXPANEL_EVENTS.SETTINGS_CLICK);
    });
};

/**
 * Initialize OneTrust Cookie banner DOM events.
 */
const initListeners = () => {
    popupEvents();
    settingsEvents();
    detailsEvents();
};

/**
 * The main OneTrust SDK wrapper, will be triggered once (and if) GTM loads OneTrust SDK.
 */
export const startOneTrust = () => {
    window.addEventListener(ONE_TRUST_LOADED_EVENT, () => {
        const closedBannerCookie = getClosedBannerCookie();
        const cookieBanner = document.getElementById(ONETRUST_SELECTORS.MAIN);

        if (closedBannerCookie || !cookieBanner) {
            if (isSafari()) {
                updateClosedBannerCookie(closedBannerCookie);
            }
            return;
        }

        statsCount(METRICS.SHOW);
        sendBigQuery(EVENTS.IMPRESSION);
        trackMixpanel(MIXPANEL_EVENTS.IMPRESSION);

        initListeners();
    });
};
