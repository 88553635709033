/**
 * The qualtrics analytics report check interval.
 * @type {Number}
 */
export const CHECK_INTERVAL = 5 * 1000; // 5 seconds

/**
 * The maximum report checks allowed.
 * @type {Number}
 */
export const MAX_CHECK_ATTEMPTS = 24;

/**
 * The qualtrics analytics events.
 * @type {Object<String>}
 */
export const EVENTS = {
    VIEW: 'qualtrics.view',
    CLICK: 'qualtrics.click',
};

/**
 * The qualtrics main banner selector.
 * @type {String}
 */
export const QUALTRICS_MAIN_SELECTOR = 'QSIPopOver';

/**
 * The survey title name selector.
 * @type {String}
 */
export const SURVEY_NAME_SELECTOR = `.${QUALTRICS_MAIN_SELECTOR} strong > span`;

/**
 * Defined by layout service
 * Enabled value 'true'
 */
export const META_KEY = 'gtm:qualtrics_enabled';
