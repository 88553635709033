import { stats, bigQuery, mixpanel } from '@fiverr-private/obs';
import { getContext } from '@fiverr-private/fiverr_context';
import { EVENTS, METRICS, COUNT_PREFIX, MIXPANEL_EVENTS } from './constants';

interface EventConfig {
    group: string;
    type: string;
    [key: string]: any;
}

const prefixedStats = stats.prefix(COUNT_PREFIX);

/**
 * Sends count event
 * @param {string} metric Metric name
 */
const statsCount = (metric: string) => prefixedStats.count(metric);

/**
 *
 * @param {Object} event Event configuration
 * @param {string} event.group Group name
 * @param {string} event.type Event type
 * @param {Object<string, any>} enrichment Event enrichments
 */
const sendBigQuery = (event: EventConfig, enrichment: Record<string, any> = {}) => {
    const { pageCtxId, userGuid, userId } = getContext();

    const staticEnrichment = {
        user: { id: userId },
        uid: userGuid,
        page: { ctx_id: pageCtxId },
    };

    bigQuery.send({
        ...event,
        ...enrichment,
        ...staticEnrichment,
    });
};

/**
 * Track mixpanel event
 * @param {string} eventName Event name
 * @param {object} properties Event properties
 */
const trackMixpanel = (eventName: string, properties: Record<string, any> = {}) => {
    const { userGuid, userId } = getContext();

    mixpanel.track(eventName, {
        ...properties,
        'User Id': userId,
        'User Guid': userGuid,
    });
};

export { statsCount, sendBigQuery, trackMixpanel, METRICS, EVENTS, MIXPANEL_EVENTS };
