import { get } from 'lodash';
import { mixpanel } from '@fiverr-private/obs';
import { sendBigQuery } from '../tracking';
import { EVENTS as BIG_QUERY_EVENTS } from '../tracking/constants';
import { QUALTRICS_MAIN_SELECTOR, SURVEY_NAME_SELECTOR, EVENTS, CHECK_INTERVAL, MAX_CHECK_ATTEMPTS } from './constants';

/**
 * Gets the gtm data layer user type enrichment.
 */
const getUserType = () => {
    const [firstDataLayer] = window.dataLayer || [];

    return firstDataLayer && firstDataLayer.user_type;
};

/**
 * Gets the qualtrics survey name.
 * @return {String}
 */
const getSurveyName = () => {
    /**
     * @TODO
     * Surway name should not be taken by query selector.
     * QSI open API should be used.
     */
    const surveyNameHolder = document.querySelector(SURVEY_NAME_SELECTOR) as HTMLElement;
    return (surveyNameHolder && surveyNameHolder.innerText) || '';
};

/**
 * Tracks qualtrics event.
 * @param {String} eventName The event to report with.
 */
const trackEvent = (eventName: string) => {
    const interceptId = get(window.QSI, 'config.interceptId');

    mixpanel.track(eventName, {
        user_type: getUserType(),
        survey: getSurveyName(),
        interceptId,
    });
};

/**
 * Intercepts a given object's method once with a given interceptor.
 * @param {Object} object The object to intercepts it's method.
 * @param {String} method The method name to intercept.
 * @param {Function} interceptor The interceptor function to run upon first call.
 */
const interceptOnce = (object: Record<string, any>, method: string, interceptor: () => void): void => {
    const original = object[method];
    object[method] = function (...args: any[]) {
        interceptor();

        const result = original.apply(object, args);
        object[method] = original;
        return result;
    };
};

/**
 * Sets qualtrics banner click method
 * @see interceptOnce
 */
const setBannerClickInterceptor = () => {
    interceptOnce(window.QSI.util, 'sendStat', () => {
        trackEvent(EVENTS.CLICK);
    });
};

/**
 * Gets the qualtrics intercept id from the iframe name attribute.
 * @return {String}
 */
const getInterceptIdFallback = (): string | undefined =>
    Array.from(document.querySelectorAll('iframe'))
        .find((iframe) => iframe.getAttribute('name')?.includes('survey-iframe'))
        ?.getAttribute('name')
        ?.replace('survey-iframe-', '');

/**
 * Start Qualtrics analytic
 */
export const startQualtrics = () => {
    let checkAttempt = 0;
    const checkTimer = setInterval(check, CHECK_INTERVAL);

    check();

    /**
     * Checks if qualtrics main survey is visible and initialize it's analytics if so.
     */
    function check() {
        checkAttempt = checkAttempt + 1;

        if (checkAttempt > MAX_CHECK_ATTEMPTS) {
            clearInterval(checkTimer);
            return;
        }

        const banner = document.querySelector(`.${QUALTRICS_MAIN_SELECTOR}`) as HTMLElement;
        const isVisible = banner && banner.style.top.indexOf('-') === -1;

        if (!isVisible || !checkTimer) {
            return;
        }
        const interceptId = get(window.QSI, 'config.interceptId') || getInterceptIdFallback();

        clearInterval(checkTimer);

        trackEvent(EVENTS.VIEW);

        if (interceptId) {
            sendBigQuery(BIG_QUERY_EVENTS.SURVEYS_IS_SHOWN, {
                user_type: getUserType(),
                survey: {
                    id: interceptId,
                    name: getSurveyName(),
                },
            });
        }

        /**
         * QS click event might be fully ignored
         * in case used will do action right after modal showed
         * There is a timeline window of timeout delay 5sec
         *
         * @TODO
         * Integration should be done in a proper way
         * timeouts are not acceptable.
         */
        setBannerClickInterceptor();
    }
};
