export const METRICS = {
    SHOW: 'show',
    ACCEPT: 'accept',
    SETTINGS_CLICK: 'settings-click',
};

export const EVENTS = {
    IMPRESSION: {
        group: 'cookies_popup_impression',
        type: 'cookies_popup.show$viewport',
    },
    ACCEPT: {
        group: 'user_notification',
        type: 'cookies_popup.allow_all_btn',
    },
    REJECT_ALL: {
        group: 'user_notification',
        type: 'cookies_popup.reject_all_btn',
    },
    SETTINGS_CLICK: {
        group: 'user_notification',
        type: 'cookies_popup.open_preference',
    },
    VIEW_SETTINGS_DETAILS: {
        group: 'user_notification',
        type: 'cookies_settings.details',
    },
    ALLOW_ALL_SETTINGS: {
        group: 'user_notification',
        type: 'cookies_settings.allow_all',
    },
    ALLOW_SETTINGS: {
        group: 'user_notification',
        type: 'cookies_settings.allow',
    },
    ALLOW_ALL_PREFERENCES: {
        group: 'user_notification',
        type: 'cookies_popup.allow_all_preference',
    },
    SETTINGS_SEARCH: {
        group: 'user_notification',
        type: 'cookies_settings.search',
    },
    VIEW_DETAILS: {
        group: 'user_notification',
        type: 'cookies_settings.view_cookies',
    },
    SETTINGS_CLOSE: {
        group: 'user_notification',
        type: 'cookies_settings.close',
    },
    CLOSE: {
        group: 'user_notification',
        type: 'cookies_popup.close',
    },
    SELECT_CATEGORY: {
        group: 'user_notification',
        type: 'cookies_settings.select_category',
    },
    ALLOW_CATEGORY: {
        group: 'user_notification',
        type: 'cookies_settings.allow_category',
    },
    MORE_INFO: {
        group: 'user_notification',
        type: 'cookies_settings.more_info',
    },
    CONFIRM: {
        group: 'user_notification',
        type: 'cookies_settings.confirm',
    },
    SURVEYS_IS_SHOWN: {
        group: 'user_impression',
        type: 'surveys_is_shown',
    },
};

export const MIXPANEL_EVENTS = {
    IMPRESSION: 'Cookies Consent - Popup - show$viewport',
    ACCEPT: 'Cookies Consent - Popup - allow_all_btn',
    REJECT_ALL: 'Cookies Consent - Popup - reject_all_btn',
    ALLOW_ALL_PREFERENCES: 'Cookies Consent - Popup - allow_all_preference',
    CLOSE: 'Cookies Consent - Popup - close',
    SETTINGS_CLICK: 'Cookies Consent - Popup - open_preference',
    SETTINGS_CLOSE: 'Cookies Consent - Settings - close',
    MORE_INFO: 'Cookies Consent - Settings - more_info',
    CONFIRM: 'Cookies Consent - Settings - confirm',
    SELECT_CATEGORY: 'Cookies Consent - Settings - select_category',
    ALLOW_CATEGORY: 'Cookies Consent - Settings - allow_category',
    VIEW_SETTINGS_DETAILS: 'Cookies Consent - Settings - details',
    ALLOW_ALL_SETTINGS: 'Cookies Consent - Settings - allow_all',
    SETTINGS_SEARCH: 'Cookies Consent - Settings - search',
    ALLOW_SETTINGS: 'Cookies Consent - Settings - allow',
    VIEW_DETAILS: 'Cookies Consent - Settings - view_cookies',
};

/**
 * Counter prefix
 * @type {string}
 */
export const COUNT_PREFIX = 'cookie-consent.banner';
