/**
 * The close banner cookie expiry time.
 * @type {Number}
 */
export const COOKIES_EXPIRES = 365 * 24 * 60 * 60 * 1000; // one year

/**
 * The close banner cookie name.
 * @type {String}
 */
export const CLOSED_BANNER_COOKIE_NAME = 'OptanonAlertBoxClosed';

/**
 * The cookie consent categories.
 * @type {Object<String>}
 */
export const CATEGORIES_MAPPING = {
    C0001: 'Strictly Necessary Cookies',
    C0002: 'Performance Cookies',
    C0003: 'Functional Cookies',
    C0004: 'Targeting Cookies',
};

/**
 * The OneTrust DOM selectors.
 * @type {Object<String>}
 */
export const ONETRUST_SELECTORS = {
    MAIN: 'onetrust-banner-sdk',
    CLOSE_BANNER: 'onetrust-close-btn-container',
    ACCEPT: 'onetrust-accept-btn-handler',
    REJECT_ALL: 'onetrust-reject-all-handler',
    SETTINGS_BUTTON: 'onetrust-pc-btn-handler',
    SETTINGS_CLOSE: 'close-pc-btn-handler',
    ALLOW_ALL_BUTTON: 'accept-recommended-btn-handler',
    CATEGORIES_DETAILS: 'category-host-list-handler',
    CATEGORIES_GROUP: 'category-group',
    CATEGORIES_ITEMS: 'category-item',
    CATEGORIES_TOGGLES: 'category-switch-handler',
    SETTINGS_MORE_INFO: 'privacy-notice-link',
    CONFIRM_SETTINGS: 'save-preference-btn-handler',
    ALLOW_ALL_SETTINGS: 'select-all-hosts-groups-handler',
    SETTINGS_DETAILS: 'host-box',
    ALLOW_SETTINGS: 'host-checkbox-handler',
    SETTINGS_SEARCH: 'vendor-search-handler',
};

export const ONE_TRUST_LOADED_EVENT = 'onetrustloaded';
